.product-grid {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 100px;
}

.product-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  transition: transform 0.3s ease;
  cursor: pointer;
  width: 25%;
}

.product-cell:hover {
  transform: scale(1.02);
}

.title {
  margin-top: 5px;

}
@media only screen and (max-width: 766px) {
  .title {
    font-size: 22px;
  }
}

.product:hover {
  opacity: 0.9;
}

.product img {
  width: 100%;
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.product img:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.product > a {
  color: black;
  text-decoration: none;
}

