.presentation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding-top: 170px;
  padding-bottom: 110px;
}
@media only screen and (max-width: 766px) {
  .presentation {
    padding-bottom: 250px;
    padding-top: 260px;
  }
}
.presentation h1 {
  font-size: 48px;
  max-width: 900px;
  color: black;

  margin-bottom: 30px;
  position: relative;
}
@media only screen and (max-width: 766px) {
  .presentation h1 {
    font-size: 34px;
    margin-inline: 16px;
  }
}

.presentation h1 span {
  display: inline-block;
}

.presentation h1 span.g {
  color: #4285f4;
}

.presentation h1 span.o {
  color: #db4437;
}

.presentation h1 span.o2 {
  color: #f4b400;
}

.presentation h1 span.g2 {
  color: #4285f4;
}

.presentation h1 span.l {
  color: #0f9d58;
}

.presentation h1 span.e {
  color: #db4437;
}

.presentation a {
  text-decoration: underline;
  font-size: 14pt;
  color: blue;
  position: relative;
  animation-name: move;
  animation-duration: 2s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes move {
  0% {
    top: 0;
    opacity: 40%;
  }
  50% {
    top: 5px;
    opacity: 100%;
  }
  100% {
    top: 0;
    opacity: 40%;
  }
}
