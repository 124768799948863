.navigation {
  height: 60px;
  display: flex;
  flex-direction: row;
  margin-inline: 160px;
}
@media only screen and (max-width: 766px) {
  .navigation {
    margin-inline: 16px;
  }
  }

.previous > a {
  text-decoration: none;
  color: grey;
}

.previous {
  display: flex;
  margin-right: auto;
}

.previous > .MuiSvgIcon-root {
  margin-top: 14px;
  color: grey;
}

.next {
  display: flex;
  margin-left: auto;
}

.next > a {
  text-decoration: none;
  color: grey;
}

.next > .MuiSvgIcon-root {
  margin-top: 14px;
  color: grey;
}

