.main__margin__layout > h1 {
  text-align: center;
  font-size: 60px;
}

.main__margin__layout > h2 {
  color: grey;
  font-weight: 300;
  text-align: center;
}

.main__margin__layout > h3 {
  font-size: 26px;
}

.main__margin__layout > h4 {
  font-size: 22px;
}

.main__margin__layout {
  min-height: 900px;
  padding-inline: 200px;
}
@media only screen and (max-width: 766px) {
  .main__margin__layout {
    min-height: 900px;
    padding-inline: 16px;
  }
  }

.arrow__title {
  flex-direction: row;
  display: flex;
}
.arrow__title > p {
  margin-top: 3px;
  margin-left: 3px;
}

.fleche_title {
  flex-direction: row;
  display: flex;
}

img {
  width: 100%;
}

.info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20%;
  margin-right: 20%;
  color: grey;
}
@media only screen and (max-width: 766px) {
  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  }

.image_DG {
  flex-direction: row;
  display: flex;
}
@media only screen and (max-width: 766px) {
  .image_DG {
    flex-direction: column;
    display: flex;
    margin-bottom: 32px;
    margin-top: 32px;
  }
  }

.imggauche {
  flex: 1;
  width: 100%;
  height: fit-content;
}

.mid {
  flex: 0.05;
}

.imgdroite {
  flex: 1;
  display: flex;
  width: 100%;
  height: fit-content;
}

.txtdroite {
  flex: 1;
  display: flex;
  width: 100%;
  height: fit-content;
  flex-direction: column;
  color: grey;
}

