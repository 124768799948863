.header {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: right;
  width: 100%;
  height: 80px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  z-index: 1;
  background-color: white;
  transition: top 0.3s;
}

.header.hidden {
  top: -100px;
}

.item__header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.work__bg {
  width: 90px;
  text-align: center;
}

.about__bg {
  width: 150px;
  text-align: center;
  margin-right: 95px;
}
@media only screen and (max-width: 766px) {
  .about__bg {
    margin-right: 0px;
  }
}

.work__bg > a {
  text-decoration: none;
  font-size: 18pt;
  color: black;
}

.about__bg > a {
  text-decoration: none;
  font-size: 18pt;
  color: black;
}

.active-green > a {
  text-decoration: underline;
  font-weight: bold;
}
