.footer {
  height: 70px;
  margin-top: 32px;
}

.greyline {
  height: 1px;
  background-color: lightgray;
  margin-inline: 120px;
}
@media only screen and (max-width: 766px) {
  .greyline {
    height: 1px;
    background-color: lightgray;
    margin-inline: 16px;
  }
}

.links {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.links > a {
  padding-inline: 20px;
  padding-top: 20px;
  font-size: 14pt;
  text-decoration: none;
  color: black;
}
