.content__about {
  padding-inline: 250px;
  padding-top: 100px;
  padding-bottom: 120px;
}
@media only screen and (max-width: 766px) {
  .content__about {
    padding-inline: 0px;
    padding-left: 16px;
    padding-top: 60px;
    padding-bottom: 120px;
  }
}

.content__about h1 {
  font-size: 32px;
  margin-top: 0px;
}

.content__about p {
  font-size: 18px;
}

.container__photo {
  display: flex;
  flex: 0.5;
  align-items: flex-start;
}

.container__texte {
  flex: 1;
  padding-right: 50px;
}
@media only screen and (max-width: 766px) {
  .container__texte {
    flex: 1;
    padding-right: 16px;
  }
  }
.container__photo img {
  border-radius: 10px;
  object-fit: contain;
  width: 80%;
}
@media only screen and (max-width: 766px) {
  .container__photo img {
    margin-top: 30px;
    border-radius: 10px;
    object-fit: contain;
    width: 95%;
  }
  }

.container__about {
  display: flex;
  flex-direction: row;
  padding-top: 50px;
}
@media only screen and (max-width: 766px) {
.container__about {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
}
}

.Cv {
  text-align: center;
}